import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./menu.css";

const DropDownMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [styleOpen, setStyleOpen] = useState({ height: "0px" });

  const abreMenu = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setStyleOpen({ display: "unset" });
    } else {
      setStyleOpen({ height: "none" });
    }
  }, [isOpen]);

  return (
    <li className={`treeview ${isOpen ? "open" : ""}`}>
      <a href="!#" aria-expanded={isOpen} onClick={(e) => abreMenu(e)}>
        <FontAwesomeIcon icon={props.icone} /> <span>{props.label} </span>
        <FontAwesomeIcon icon="chevron-right" className="arrow" />
      </a>
      <ul className="treeview-menu" style={styleOpen}>
        {props.children}
      </ul>
    </li>
  );
};

DropDownMenu.propTypes = {
  label: PropTypes.string.isRequired,
  icone: PropTypes.string.isRequired,
};

export default DropDownMenu;
