import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { logout } from "../../services/auth";
import Loader from "../../components/loader";

const Logout = () => {
  useEffect(() => {
    logout();
  }, []);
  return (
    <React.Fragment>
      <Loader loading={true} />
      <Redirect to="/login" />
    </React.Fragment>
  );
};

export default Logout;
