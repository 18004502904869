import "../icones";

import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Loader from "../components/loader";
import PrivateRoute from "../components/router/PrivateRoute";
import Login from "../pages/login";
import Logout from "../pages/login/logout";

const Default = lazy(() => import("../pages/default"));
const PedidosMusica = lazy(() => import("../pages/pedido-musica"));
const PedidosOracao = lazy(() => import("../pages/pedido-oracao"));
const Eventos = lazy(() => import("../pages/eventos"));
const EventosForm = lazy(() => import("../pages/eventos/eventos-form"));
const BolsaEmpregos = lazy(() => import("../pages/bolsa-empregos"));
const BolsaEmpregosForm = lazy(() =>
  import("../pages/bolsa-empregos/emprego-form")
);
const CapinzalNegocios = lazy(() => import("../pages/capinzal-negocios"));
const CapinzalNegociosForm = lazy(() =>
  import("../pages/capinzal-negocios/capinzal-negocios-form")
);
const NotasFalecimento = lazy(() => import("../pages/notas-falecimento"));
const NotasFalecimentoForm = lazy(() =>
  import("../pages/notas-falecimento/notas-falecimento-form")
);
const Banner = lazy(() => import("../pages/banner"));
const BannerForm = lazy(() => import("../pages/banner/banner-form"));

const Equipe = lazy(() => import("../pages/equipe"));
const EquipeForm = lazy(() => import("../pages/equipe/equipe-form"));

const Programacao = lazy(() => import("../pages/programacao"));
const ProgramacaoForm = lazy(() =>
  import("../pages/programacao/programacao-form")
);

const Usuario = lazy(() => import("../pages/usuario"));
const UsuarioForm = lazy(() => import("../pages/usuario/usuario-form"));

const Promocao = lazy(() => import("../pages/promocoes"));
const PromocaoForm = lazy(() => import("../pages/promocoes/promocao-form"));
const PromocaoInscritos = lazy(() => import("../pages/promocoes/inscritos"));
const PromocaoGanhadores = lazy(() => import("../pages/promocoes/sorteio"));
const Noticias = lazy(() => import("../pages/noticias"));
const NoticiasManutencao = lazy(() => import("../pages/noticias/manutencao"));

const Categorias = lazy(() => import("../pages/categoria"));
const CategoriasForm = lazy(() => import("../pages/categoria/categoria-form"));

const Enquete = lazy(() => import("../pages/enquete"));
const EnqueteForm = lazy(() => import("../pages/enquete/enquete-form"));

const Podcast = lazy(() => import("../pages/podcast"));
const PodcastForm = lazy(() => import("../pages/podcast/podcast-form"));

const supportsHistory = "pushState" in window.history;

const Routes = () => {
  return (
    <BrowserRouter
      basename={process.env.PUBLIC_URL}
      forceRefresh={!supportsHistory}
    >
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        <Switch>
          <PrivateRoute exact path="/" component={Default} />
          <PrivateRoute path="/pedido-musica" component={PedidosMusica} />
          <PrivateRoute path="/pedido-oracao" component={PedidosOracao} />
          <PrivateRoute path="/eventos/form/:id?" component={EventosForm} />
          <PrivateRoute path="/eventos" component={Eventos} />
          <PrivateRoute
            path="/bolsa-empregos/form/:id?"
            component={BolsaEmpregosForm}
          />
          <PrivateRoute path="/bolsa-empregos" component={BolsaEmpregos} />
          <PrivateRoute
            path="/capinzal-negocios/form/:id?"
            component={CapinzalNegociosForm}
          />
          <PrivateRoute
            path="/capinzal-negocios"
            component={CapinzalNegocios}
          />
          <PrivateRoute
            path="/notas-falecimento/form/:id?"
            component={NotasFalecimentoForm}
          />
          <PrivateRoute
            path="/notas-falecimento"
            component={NotasFalecimento}
          />
          <PrivateRoute path="/banner/form/:id?" component={BannerForm} />
          <PrivateRoute path="/banner" component={Banner} />

          <PrivateRoute path="/equipe/form/:id?" component={EquipeForm} />
          <PrivateRoute path="/equipe" component={Equipe} />

          <PrivateRoute
            path="/programacao/form/:id?"
            component={ProgramacaoForm}
          />
          <PrivateRoute path="/programacao" component={Programacao} />

          <PrivateRoute
            path="/promocoes/:id/inscritos"
            component={PromocaoInscritos}
          />
          <PrivateRoute
            path="/promocoes/:id/ganhadores"
            component={PromocaoGanhadores}
          />

          <PrivateRoute path="/promocoes/form/:id?" component={PromocaoForm} />
          <PrivateRoute path="/promocoes" component={Promocao} />

          <PrivateRoute path="/usuarios/form/:id?" component={UsuarioForm} />
          <PrivateRoute path="/usuarios" component={Usuario} />

          <PrivateRoute
            path="/categorias/form/:id?"
            component={CategoriasForm}
          />
          <PrivateRoute path="/categorias" component={Categorias} />

          <PrivateRoute
            path="/noticias/manutencao/:id?"
            component={NoticiasManutencao}
          />
          <PrivateRoute path="/noticias" component={Noticias} />

          <PrivateRoute path="/enquetes/form/:id?" component={EnqueteForm} />
          <PrivateRoute path="/enquetes" component={Enquete} />

          <PrivateRoute path="/podcast/form/:id?" component={PodcastForm} />
          <PrivateRoute path="/podcast" component={Podcast} />

          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
export default Routes;
