import React from "react";
import Header from "./header";
import SideBar from "./side-bar";
import useWindowDimensions from "../../hooks/useWindowDimensions.hook";

const Layout = (props) => {
  const { height } = useWindowDimensions();

  return (
    <div className="wrapper">
      <Header />
      <SideBar />
      <div className="content-wrapper" style={{ minHeight: `${height}px` }}>
        <section className="content no-gutters">{props.children}</section>
      </div>
    </div>
  );
};

export default Layout;
