/* eslint-disable jsx-a11y/label-has-for */

import React from "react";
import PropTypes from "prop-types";

const Select = ({ field, form: { touched, errors }, ...props }) => {
  const { label, name, value } = props;
  const classError =
    touched[field.name] && errors[field.name] ? "has-error" : "";

  return (
    <div className={`form-group ${classError}`}>
      <label htmlFor={name}>{label}</label>
      <select
        className={`form-control ${classError} boxed`}
        id={name}
        value={value}
        {...field}
        {...props}
      >
        {props.children}
      </select>
      {touched[field.name] && errors[field.name] && (
        <span className="has-error">{errors[field.name]}</span>
      )}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string
};

Select.defaultProps = {
  placeholder: "",
  type: "text"
};

export default Select;
