const getToken = () => {
  return localStorage.getItem("radiocapinzal1540");
};

const setToken = token => {
  localStorage.setItem("radiocapinzal1540", token);
};

const removeToken = () => {
  localStorage.removeItem("radiocapinzal1540");
};

export { getToken, setToken, removeToken };
