import React, { useState } from "react";

import UserContext from "./user-context";

const UserProvider = ({ children }) => {
  const [usuario, setUsuario] = useState({});
  return (
    <UserContext.Provider value={{ usuario, setUsuario }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
