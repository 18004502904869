import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faFacebook,
  faWhatsapp,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import {
  faBullhorn,
  faDollarSign,
  faCross,
  faNewspaper,
  faTrophy,
  faBuilding,
  faSearch,
  faMapMarkedAlt,
  faEnvelope,
  faPhone,
  faClock,
  faCheck,
  faPowerOff,
  faHome,
  faChevronRight,
  faChevronDown,
  faAddressCard,
  faScroll,
  faPrayingHands,
  faMusic,
  faThumbsUp,
  faTrash,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faSave,
  faDoorOpen,
  faList,
  faUser,
  faEye,
  faBars,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBullhorn,
  faDollarSign,
  faCross,
  faNewspaper,
  faTrophy,
  faBuilding,
  faSearch,
  faFacebook,
  faWhatsapp,
  faTwitter,
  faInstagram,
  faYoutube,
  faMapMarkedAlt,
  faEnvelope,
  faPhone,
  faClock,
  faCheck,
  faPowerOff,
  faHome,
  faChevronRight,
  faChevronDown,
  faAddressCard,
  faScroll,
  faPrayingHands,
  faMusic,
  faThumbsUp,
  faTrash,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faSave,
  faDoorOpen,
  faList,
  faUser,
  faEye,
  faBars,
  faDotCircle
);

export default library;
