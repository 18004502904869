import React, { useState, useContext } from "react";
import { Formik, Field } from "formik";
import * as yup from "yup";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";

import Loader from "../../components/loader";
import { Input } from "../../components/form";
import { autenticate } from "../../services/auth";

import UserContext from "../../context/user-context";
import Logo from "../../assets/images/logo.png";
import "./login.css";

const MySwal = withReactContent(Swal);

const validationSchema = yup.object().shape({
  username: yup.string().required("Necessário informar o seu login"),
  password: yup.string().required("Necessário informar a senha"),
});

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { setUsuario } = useContext(UserContext);

  const handleSubmit = async (values) => {
    setLoading(true);
    const result = await autenticate(values);

    //const usuario = await buscaDadosUsuario();
    console.log(result);
    setUsuario(result);
    setLoading(false);

    if (result) {
      history.push("/");
    } else {
      setLoading(false);
      MySwal.fire({
        icon: "error",
        title: <p>Usuário ou senha inválidos</p>,
      });
    }
  };

  return (
    <div className="container h-p100">
      <div className="row align-items-center justify-content-md-center h-p100">
        <div className="col-lg-4 col-md-8 col-12">
          <Loader loading={loading} />
          <div className="login-box">
            <div className="login-box-body">
              <img className="logo" src={Logo} alt="Rádio Capinzal" />

              <Formik
                initialValues={{
                  username: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(propsFormik) => (
                  <form id="login-form" onSubmit={propsFormik.handleSubmit}>
                    <Field
                      label="Usuário"
                      id="username"
                      name="username"
                      classType="underlined"
                      value={propsFormik.values.username}
                      component={Input}
                    />
                    <Field
                      label="Senha"
                      id="password"
                      name="password"
                      classType="underlined"
                      type="password"
                      value={propsFormik.values.password}
                      component={Input}
                    />

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
