/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import {} from "formik";

const InputMasked = ({ field, form: { touched, errors }, ...props }) => {
  const { label, value, name, type, mask } = props;
  const classError =
    touched[field.name] && errors[field.name] ? "has-error" : "";
  return (
    <React.Fragment>
      <div className={`form-group ${classError}`}>
        <label htmlFor={name}>{label}</label>
        <InputMask
          className={`form-control boxed ${classError}`}
          id={name}
          type={type}
          value={value}
          mask={mask}
          {...field}
          {...props}
        />
        {touched[field.name] && errors[field.name] && (
          <span className="has-error">{errors[field.name]}</span>
        )}
      </div>
    </React.Fragment>
  );
};

InputMasked.propTypes = {
  label: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string
};

InputMasked.defaultProps = {
  placeholder: "",
  type: "text"
};

export default InputMasked;
