/* eslint-disable jsx-a11y/label-has-for */

import React from "react";
import PropTypes from "prop-types";

const Input = ({ field, form: { touched, errors }, ...props }) => {
  const { label, value, name, type, classType, placeholder, style } = props;
  const classError =
    touched[field.name] && errors[field.name] ? "has-error" : "";
 
  return (
    <div className={`form-group ${classError}`}>
      <label htmlFor={name}>{label}</label>
      <input
        className={`form-control ${classError} ${classType}`}
        id={name}
        type={type}
        value={value}
        placeholder={placeholder}
        {...field}
        style={style}
        {...props}
      />
      {touched[field.name] && errors[field.name] && (
        <span className="has-error">{errors[field.name]}</span>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  classType: PropTypes.string,
  style: PropTypes.object,
};

Input.defaultProps = {
  placeholder: "",
  type: "text",
  classType: "boxed",
  style: {},
};

export default Input;
