import React from "react";

import DropDownMenu from "./menu/DropDownMenu";
import MenuItem from "./menu/MenuItem";

const SideBar = () => {
  return (
    <aside className="main-sidebar">
      <section className="sidebar">
        <ul className="sidebar-menu" data-widget="tree">
          <MenuItem label="Dashboard" link="/" icone="home" />
          <MenuItem label="Notícias" link="/noticias" icone="newspaper" />
          <MenuItem
            label="Pedidos de Oração"
            link="/pedido-oracao"
            icone="praying-hands"
          />
          {/*
          <MenuItem
            label="Pedidos de Música"
            link="/pedido-musica"
            icone="music"
          />
          */}
          <DropDownMenu icone="scroll" label="Publicações">
            <MenuItem label="Banner" icone="dot-circle" link="/banner" />
            <MenuItem
              label="Bolsa de Empregos"
              icone="dot-circle"
              link="/bolsa-empregos"
            />
            <MenuItem
              label="Capinzal Negócios"
              icone="dot-circle"
              link="/capinzal-negocios"
            />
            <MenuItem label="Enquetes" icone="dot-circle" link="/enquetes" />
            <MenuItem label="Eventos" icone="dot-circle" link="/eventos" />
            <MenuItem label="Promoções" icone="dot-circle" link="/promocoes" />
            <MenuItem
              label="Notas de Falecimento"
              icone="dot-circle"
              link="/notas-falecimento"
            />
            <MenuItem label="Podcast" icone="dot-circle" link="/podcast" />
          </DropDownMenu>

          <DropDownMenu icone="address-card" label="Cadastros">
            <MenuItem
              label="Categorias"
              icone="dot-circle"
              link="/categorias"
            />
            <MenuItem label="Equipe" icone="dot-circle" link="/equipe" />
            <MenuItem
              label="Programação"
              icone="dot-circle"
              link="/programacao"
            />
            <MenuItem label="Usuários" icone="dot-circle" link="/usuarios" />
          </DropDownMenu>
        </ul>
      </section>
    </aside>
  );
};

export default SideBar;
