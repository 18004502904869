import React from "react";
import "./assets/css/bootstrap-extend.css";
import "./assets/css/master_style.css";
import "./assets/css/skins/_all-skins.css";

import "./App.css";
import UserProvider from "./context/user-provider";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Routes from "./routes";

function App() {
  return (
    <UserProvider>
      <Routes />
    </UserProvider>
  );
}

export default App;
