import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuth } from "../../services/auth";

import Layout from "../layout";

const PrivateRoute = ({ component: Component, ...props }) => {
  const [estaAutenticado, setEstaAutenticado] = useState(true);

  const checkAuth = async () => {
    try {
      const result = await isAuth();
      setEstaAutenticado(result);
    } catch (e) {
      setEstaAutenticado(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Route
      {...props}
      render={(innerProps) =>
        estaAutenticado ? (
          <Layout>
            <Component {...innerProps} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
