import api from "./api";
import { getToken, setToken, removeToken } from "./token";

const isAuth = async () => {
  //console.log("isAuth");
  const token = getToken();

  //console.log(token);
  if (token) {
    try {
      await checkToken();
      return true;
    } catch (e) {
      throw new Error(e.message);
    }
  }
  return false;
};

const checkToken = async () => {
  try {
    await api.get("/adm/check-token");
    return true;
  } catch (e) {
    throw new Error(e.message);
  }
};

const autenticate = async (values) => {
  let result;
  try {
    result = await api.post("/login_check", values);
  } catch (e) {
    return null;
  }

  if (result) {
    console.log(result);
    setToken(result.data.token);
    return result.data.usuario;
  }
  return result.data.usuario;
};

const logout = () => {
  removeToken();
  return true;
};

export { isAuth, autenticate, logout };
