/* eslint-disable jsx-a11y/label-has-for */

import React from "react";
import PropTypes from "prop-types";

import {} from "formik";

const InputFile = ({ field, form: { touched, errors }, ...props }) => {
  const { label, value, name } = props;
  const classError =
    touched[field.name] && errors[field.name] ? "is-invalid" : "";
  return (
    <React.Fragment>
      <div className="input-group mb-3">
        <div className="custom-file">
          <input
            id={name}
            type="file"
            value={value}
            className={`custom-file-input ${classError}`}
            {...field}
            {...props}
          />
          <label className="custom-file-label" for="inputGroupFile01">
            {label}
          </label>
        </div>
      </div>

      {touched[field.name] && errors[field.name] && (
        <div className="invalid-feedback">{errors[field.name]}</div>
      )}
    </React.Fragment>
  );
};

InputFile.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

InputFile.defaultProps = {
  placeholder: "",
  type: "text",
};

export default InputFile;
