import React from "react";
import PropTypes from "prop-types";

const TextArea = ({ field, form: { touched, errors }, ...props }) => {
  const { label, value, name, type } = props;
  const classError =
    touched[field.name] && errors[field.name] ? "has-error" : "";

  return (
    <React.Fragment>
      <div className={`form-group ${classError}`}>
        <label htmlFor={name}>{label}</label>
        <textarea
          className={`form-control boxed ${classError}`}
          id={name}
          type={type}
          value={value}
          {...field}
          {...props}
        ></textarea>
        {touched[field.name] && errors[field.name] && (
          <span className="has-error">{errors[field.name]}</span>
        )}
      </div>
    </React.Fragment>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string
};

TextArea.defaultProps = {
  placeholder: "",
  type: "text"
};

export default TextArea;
