import axios from "axios";
import { getToken } from "./token";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.error(
      `${error.response.status} - ${error.response.statusText} --> ${error.response.data.message}`
    );
    throw new Error(error.response.data.message);
    // return Promise.reject(error.response.data.message);
  }
);

export default api;
