import React from "react";
import { WaveSpinner } from "react-spinners-kit";
const Loader = (props) => {
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1100,
  };

  return (
    <div style={style}>
      <WaveSpinner size={30} color="#0097DC" loading={props.loading} />
    </div>
  );
};

export default Loader;
