import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";

import "./menu.css";

const MenuItem = (props) => {
  const location = useLocation();
  const liRef = useRef();
  const classActive = location.pathname === props.link ? "active" : "";

  useEffect(() => {
    if (location.pathname === props.link) {
      liRef.current.parentNode.parentNode.classList.add("open");
      liRef.current.parentNode.parentNode
        .getElementsByTagName("a")[0]
        .setAttribute("aria-expanded", "true");
      liRef.current.parentNode.parentNode
        .getElementsByTagName("ul")[0]
        .setAttribute("style", "display: unset;");
    }
  }, [liRef]);

  return (
    <li className={`treeview ${classActive}`} ref={liRef}>
      <Link to={props.link}>
        {props.icone && <FontAwesomeIcon icon={props.icone} />}{" "}
        <span>{props.label} </span>
      </Link>
    </li>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  icone: PropTypes.string,
};

MenuItem.defaultProps = {
  icone: undefined,
};

export default MenuItem;
