import "./header.css";

import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import UserContext from "../../context/user-context";
import { useEffect } from "react";

import Logo from "../../assets/images/logo.png";

const Header = () => {
  const isMobile = window.innerWidth <= 576;
  const [showMenu, setShowMenu] = useState(false);
  const [showSidebar, setShowSidebar] = useState(!isMobile);
  const [classSidebarStatus, setClassSidebarStatus] = useState("");
  const { usuario } = useContext(UserContext);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleSidebar = (e) => {
    e.preventDefault();
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      if (showSidebar) {
        setClassSidebarStatus("sidebar-open");
      } else {
        setClassSidebarStatus("");
      }
    } else {
      if (showSidebar) {
        setClassSidebarStatus("");
      } else {
        setClassSidebarStatus("sidebar-collapse");
      }
    }
  }, [showSidebar]);

  const classBody = `hold-transition skin-blue sidebar-mini ${classSidebarStatus}`;

  return (
    <header className="main-header">
      <Helmet
        bodyAttributes={{
          class: classBody,
        }}
      />

      <Link to="/" className="logo">
        <span className="logo-lg">
          <img src={Logo} alt="Rádio Capinzal" className="light-logo" />
        </span>
      </Link>

      <nav className="navbar navbar-static-top">
        <a
          href="#1"
          className="sidebar-toggle"
          data-toggle="push-menu"
          role="button"
          onClick={(e) => {
            toggleSidebar(e);
          }}
        >
          <FontAwesomeIcon icon="bars" />
          <span className="sr-only">Toggle navigation</span>
        </a>
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li
              className={`dropdown user user-menu ${showMenu ? "show" : ""}`}
              onClick={() => toggleMenu()}
            >
              <a href="#1" className="dropdown-toggle" data-toggle="dropdown">
                {usuario.nome} {"  "}
                <FontAwesomeIcon icon="user" />
              </a>
              <ul className="dropdown-menu scale-up">
                <li className="user-header">
                  <FontAwesomeIcon icon="user" />
                  <p>
                    {usuario.nome}
                    <small className="mb-5">{usuario.email}</small>
                  </p>
                </li>

                <li className="user-body">
                  <div className="row no-gutters">
                    <div role="separator" className="divider col-12"></div>
                    <div className="col-12 text-left">
                      <Link className="dropdown-item" to="/logout">
                        <FontAwesomeIcon icon="power-off" /> Sair{" "}
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
